const initialState = {
  talentpoolV2: true, // used to prevent state persist from old version of TP
  fbAuthLoading: false, // used to display spinner while Firebase re-auth
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case '@@reactReduxFirebase/AUTHENTICATION_INIT_STARTED':
      return { ...state, fbAuthLoading: true };
    case '@@reactReduxFirebase/LOGIN':
    case '@@reactReduxFirebase/AUTH_EMPTY_CHANGE':
      return { ...state, fbAuthLoading: false };
    default:
      return state;
  }
};

export default appReducer;
