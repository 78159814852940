import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Utils
import { isJson } from '../utils';

const useFilterSearchParams = () => {
  const history = useHistory();

  const getFilterSearchParamsFn = () => {
    const newFilterSearchParams = {};
    const newOtherSearchParams = {};
    const searchParams = new URLSearchParams(history.location.search);

    searchParams.forEach((value, key) => {
      if (isJson(value) && Array.isArray(JSON.parse(value))) {
        if (!newFilterSearchParams[key]) {
          newFilterSearchParams[key] = [];
        }

        JSON.parse(value).forEach((item) => {
          newFilterSearchParams[key].push({ label: item[0], value: item[1] });
        });
      } else if (typeof value === 'string') {
        newOtherSearchParams[key] = value;
      }
    });

    return {
      filtersData: newFilterSearchParams,
      otherData: newOtherSearchParams,
    };
  };

  const [filterSearchParams, setFilterSearchParams] = useState(
    getFilterSearchParamsFn(),
  );

  useEffect(() => {
    setFilterSearchParams(getFilterSearchParamsFn());
  }, [history.location.search]);

  const setFilterSearchParamsFn = ({
    filtersData,
    otherData = {},
    pathname = '',
  }) => {
    const queryParams = new URLSearchParams(otherData);

    if (filtersData) {
      Object.entries(filtersData).forEach(([key, values]) => {
        queryParams.append(
          key,
          JSON.stringify(values.map((item) => [item.label, item.value])),
        );
      });
    }

    history.push({
      pathname: pathname || history.location.pathname,
      search: `?${queryParams.toString()}`,
    });
  };

  return {
    filterSearchParams,
    setFilterSearchParams: setFilterSearchParamsFn,
  };
};

export default useFilterSearchParams;
