import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { apiMiddleware } from 'redux-api-middleware';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { firebaseReducer } from 'react-redux-firebase';
import throttle from 'lodash/throttle';

import * as reducers from './reducers';
import { loadState, saveState } from './localStorage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
};

// react-redux-firebase options
const rrfConfig = {
  userProfile: 'users', // firebase root where user profiles are stored
  updateProfileOnLogin: false, // this needs to be kept as false.
  persistence: true,
  firebaseStateName: 'firebase', // should match the reducer name ('firebase' is default),
};

// initialize firebase instance
firebase.initializeApp(firebaseConfig);

const rrfProps = {
  firebase,
  config: rrfConfig,
  // dispatch: store.dispatch,
};

export default function configureStore() {
  const enhancers = [];
  const middleware = [apiMiddleware, thunk];

  // fix to prevent the need to delete LS of old version of TP
  const loadedState = loadState();
  let persistedState = {};

  if (loadedState?.app?.talentpoolV2) {
    persistedState = loadedState;
  }

  const rootReducer = combineReducers({
    ...reducers,
    firebase: firebaseReducer,
  });

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-underscore-dangle
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }

  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers,
  );

  const store = createStore(rootReducer, persistedState, composedEnhancers);
  rrfProps.dispatch = store.dispatch;

  store.subscribe(
    throttle(() => {
      saveState({
        app: store.getState().app,
      });
    }, 1000),
  );

  return store;
}

export { rrfProps };
