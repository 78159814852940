import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';

import * as serviceWorker from './serviceWorker';
import './assets/main.css';
import configureStore, { rrfProps } from './store';
import i18n from './i18n';

import App from './App';
import '@lionstep/ui/dist/index.css';

const initialState = {};
const store = configureStore(initialState);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </BrowserRouter>
    </ReactReduxFirebaseProvider>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
