import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Spinner } from '@lionstep/ui';

import styles from './spinnerFullUI.module.scss';

const SpinnerFullUI = ({
  absolute,
  className,
  withNavigation,
  ...restProps
}) => (
  <Spinner
    position={absolute ? 'absolute' : 'fixed'}
    className={classnames(styles.spinner, className, {
      [styles.withNavigation]: withNavigation,
    })}
    size={200}
    {...restProps}
  />
);

SpinnerFullUI.defaultProps = {
  absolute: false,
  className: '',
  withNavigation: false,
};

SpinnerFullUI.propTypes = {
  absolute: PropTypes.bool,
  className: PropTypes.string,
  withNavigation: PropTypes.bool,
};

export default SpinnerFullUI;
