/* eslint-disable import/prefer-default-export */

export const copyTextToClipboard = (text) => {
  const copyText = document.createElement('textarea');
  copyText.value = text;
  copyText.setAttribute('readonly', '');
  copyText.style.position = 'absolute';
  copyText.style.left = '-9999px';
  document.body.appendChild(copyText);
  copyText.select();
  document.execCommand('copy');
  document.body.removeChild(copyText);
};

export const isJson = (value) => {
  try {
    JSON.parse(value);
  } catch (e) {
    return false;
  }

  return true;
};

export const generateOptionsList = ({
  options,
  include = [],
  exclude = [],
}) => {
  if (!options) {
    return [];
  }

  if (include.length) {
    return options.filter((option) => include.includes(option.value));
  }

  if (exclude.length) {
    return options.filter((option) => !exclude.includes(option.value));
  }

  return options;
};
