import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import EN from './translations/en.json';
import DE from './translations/de.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    debug: false,
    resources: {
      en: {
        translation: EN,
      },
      de: {
        translation: DE,
      },
    },
    react: {
      wait: true,
    },
  });

export default i18n;
