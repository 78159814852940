import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import classnames from 'classnames';

// Styles
import styles from './postLoginNavbar.module.scss';

// Const
import { NAV_LINKS } from './postLoginConsts';

const NavLinPostLoginNavLink = ({ children, href, ...restProps }) => {
  const { pathname } = useLocation();

  return (
    <li
      key={href}
      className={classnames(styles.navLink, {
        [styles.active]: pathname === href,
      })}
      {...restProps}
    >
      <Link to={href}>{children}</Link>
    </li>
  );
};

const PostLoginNavLinks = (props) => (
  <ul className={styles.navLinks} {...props}>
    {NAV_LINKS.map(({ title, href }) => (
      <NavLinPostLoginNavLink href={href} key={href}>
        {title}
      </NavLinPostLoginNavLink>
    ))}
  </ul>
);

export default PostLoginNavLinks;
