import React from 'react';
import { useLocation } from 'react-router-dom';
import { Group } from '@mantine/core';

// Components
import PostLoginNavbarLogo from './PostLoginNavbarLogo';
import PostLoginNavbarNavLinks from './PostLoginNavbarNavLinks';
import PostLoginNavbarBackButton from './PostLoginNavbarBackButton';
import PostLoginNavbarDropdownMenu from './PostLoginNavbarDropdownMenu';

// Const
import { NAV_LINKS } from './postLoginConsts';

const PostLoginLayoutNavbar = () => {
  const { pathname } = useLocation();

  const shouldREnderLinks = NAV_LINKS.some(({ href }) => pathname === href);

  return (
    <nav>
      <Group gap="0" justify="space-between">
        {shouldREnderLinks ? (
          <Group gap="0">
            <PostLoginNavbarLogo />
            <PostLoginNavbarNavLinks />
          </Group>
        ) : (
          <PostLoginNavbarBackButton />
        )}
        <PostLoginNavbarDropdownMenu />
      </Group>
    </nav>
  );
};

export default PostLoginLayoutNavbar;
