import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

import useUserLogged from '../../../hooks/useUserLogged';

import ErrorBoundary from '../ErrorBoundry/ErrorBoundary';

import PostLoginLayout from '../../Layouts/PostLoginLayout';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isUserLogged = useUserLogged();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isUserLogged ? (
          <ErrorBoundary>
            <PostLoginLayout>
              <Component />
            </PostLoginLayout>
          </ErrorBoundary>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.shape().isRequired,
};

export default PrivateRoute;
