export const FETCH_SINGLE_TALENT = '@@talents/FETCH_SINGLE_REQUEST';
export const FETCH_SINGLE_TALENT_SUCCESS = '@@talents/FETCH_SINGLE_SUCCESS';
export const FETCH_SINGLE_TALENT_FAILURE = '@@talents/FETCH_SINGLE_FAILURE';

export const FETCH_TALENT_DOCUMENT = '@@talents/FETCH_TALENT_DOCUMENTS';
export const FETCH_TALENT_DOCUMENT_SUCCESS =
  '@@talents/FETCH_TALENT_DOCUMENT_SUCCESS';
export const FETCH_TALENT_DOCUMENT_FAILURE =
  '@@talents/FETCH_TALENT_DOCUMENT_FAILURE';

export const FETCH_TALENT_SPECIAL_DOCUMENT =
  '@@talents/FETCH_TALENT_SPECIAL_DOCUMENT';
export const FETCH_TALENT_SPECIAL_DOCUMENT_SUCCESS =
  '@@talents/FETCH_TALENT_SPECIAL_DOCUMENT_SUCCESS';
export const FETCH_TALENT_SPECIAL_DOCUMENT_FAILURE =
  '@@talents/FETCH_TALENT_SPECIAL_DOCUMENT_FAILURE';

export const SET_TALENT_DOCUMENTS_LOADING =
  '@@talents/SET_TALENT_DOCUMENTS_LOADING';
export const STOP_TALENT_DOCUMENTS_LOADING =
  '@@talents/STOP_TALENT_DOCUMENTS_LOADING';

export const UPLOAD_TALENT_DOCUMENTS =
  '@@talents/UPLOAD_TALENT_DOCUMENTS_REQUEST';
export const UPLOAD_TALENT_DOCUMENTS_SUCCESS =
  '@@talents/UPLOAD_TALENT_DOCUMENTS_SUCCESS';
export const UPLOAD_TALENT_DOCUMENTS_FAILURE =
  '@@talents/UPLOAD_TALENT_DOCUMENTS_FAILURE';

export const DELETE_TALENT_DOCUMENTS =
  '@@talents/DELETE_TALENT_DOCUMENTS_REQUEST';
export const DELETE_TALENT_DOCUMENTS_SUCCESS =
  '@@talents/DELETE_TALENT_DOCUMENTS_SUCCESS';
export const DELETE_TALENT_DOCUMENTS_FAILURE =
  '@@talents/DELETE_TALENT_DOCUMENTS_FAILURE';

export const FETCH_SINGLE_TALENT_JOB_INFO =
  '@@talents/FETCH_SINGLE_JOB_INFO_REQUEST';
export const FETCH_SINGLE_TALENT_JOB_INFO_SUCCESS =
  '@@talents/FETCH_SINGLE_JOB_INFO_SUCCESS';
export const FETCH_SINGLE_TALENT_JOB_INFO_FAILURE =
  '@@talents/FETCH_SINGLE_JOB_INFO_FAILURE';

export const RESET_TALENT_STATE = '@@talent/RESET_TALENT_STATE';

export const UPDATE_TALENT = '@@talent/UPDATE';
export const UPDATE_TALENT_SUCCESS = '@@talent/UPDATE_SUCCESS';
export const UPDATE_TALENT_FAILURE = '@@talent/UPDATE_FAILURE';

export const GET_TALENT_PERSONALITY_INFO =
  '@@talent/GET_TALENT_PERSONALITY_INFO';
export const GET_TALENT_PERSONALITY_INFO_SUCCESS =
  '@@talent/GET_TALENT_PERSONALITY_INFO_SUCCESS';
export const GET_TALENT_PERSONALITY_INFO_FAILURE =
  '@@talent/GET_TALENT_PERSONALITY_INFO_FAILURE';

export const ENABLE_TALENT_PERSONALITY_TEST =
  '@@talent/ENABLE_TALENT_PERSONALITY_TEST';
export const ENABLE_TALENT_PERSONALITY_TEST_SUCCESS =
  '@@talent/ENABLE_TALENT_PERSONALITY_TEST_SUCCESS';
export const ENABLE_TALENT_PERSONALITY_TEST_FAILURE =
  '@@talent/ENABLE_TALENT_PERSONALITY_TEST_FAILURE';
