import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import SpinnerFullUI from '../components/shared/Spinner/SpinnerFullUI';
import PrivateRoute from '../components/shared/PrivateRoute/PrivateRoute';
import PublicRoute from '../components/shared/PublicRoute/PublicRoute';

const Login = React.lazy(() => import('../components/Login/Login'));
const ForgotPassword = React.lazy(() =>
  import('../components/ForgotPassword/ForgotPassword'),
);
const Page404 = React.lazy(() => import('../components/404/404'));
const Talents = React.lazy(() => import('../components/Talents/Talents'));
const Talent = React.lazy(() => import('../components/Talent/Talent'));
const TalentJob = React.lazy(() => import('../components/TalentJob'));
const Jobs = React.lazy(() => import('../components/Jobs'));
const JobPage = React.lazy(() => import('../components/Job'));
const Console = React.lazy(() => import('../components/Console/Console'));
const CancelAppointment = React.lazy(() =>
  import('../components/CancelAppointment/CancelAppointment'),
);
const SimilarJobs = React.lazy(() => import('../components/SimlarJobs'));

const PrivateRoot = () => {
  const firebaseAuthLoading = useSelector((state) => state.app.fbAuthLoading);

  return (
    <>
      {firebaseAuthLoading ? (
        <SpinnerFullUI />
      ) : (
        <Suspense fallback={<SpinnerFullUI />}>
          <Switch>
            <PrivateRoute path="/" exact component={Talents} />
            <PrivateRoute path="/candidates/:id" exact component={Talent} />
            <PrivateRoute
              path="/candidates/:candidateId/jobs/:jobId"
              exact
              component={TalentJob}
            />
            <PrivateRoute path="/jobs" exact component={Jobs} />
            <PrivateRoute path="/jobs/:id" exact component={JobPage} />
            <PrivateRoute path="/console" exact component={Console} />
            <PrivateRoute
              path="/cancel-appointment/:id/:accountName"
              exact
              component={CancelAppointment}
            />
            <PrivateRoute path="/similar-jobs" exact component={SimilarJobs} />

            <PublicRoute path="/login" exact component={Login} />
            <PublicRoute
              path="/forgot-password"
              exact
              component={ForgotPassword}
            />

            <Route component={Page404} />
          </Switch>
        </Suspense>
      )}
    </>
  );
};

export default PrivateRoot;
