import React from 'react';
import { useSelector } from 'react-redux';

import Notification from './Notification';

const NotificationsCenter = () => {
  const notifications = useSelector((state) => state.notifications);

  return (
    <div className="fixed inset-0 z-101 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end">
      <div style={{ minWidth: 384 }}>
        {notifications.map((notification) => (
          <div className="w-100 mb-2" key={notification.id}>
            <Notification
              show
              id={notification.id}
              type={notification.type}
              title={notification.title}
              description={notification.description}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default NotificationsCenter;
