import React from 'react';
import { useFirebase } from 'react-redux-firebase';
import { Box, Menu } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { useDispatch } from 'react-redux';

// Actions
import { resetAppState } from '../../../../store/actions/appActions';

const PostLoginNavbarDropdownMenu = () => {
  // TODO: REMOVE START
  const dispatch = useDispatch();
  // TODO: REMOVE END
  const firebase = useFirebase();

  const logout = () => {
    firebase.logout();
    if (firebase.auth().currentUser) {
      firebase.ref(`presence/${firebase.auth().currentUser.uid}`).set(false);
    }

    dispatch(resetAppState());
  };

  return (
    <Menu position="bottom-end" shadow="sm" width={150}>
      <Menu.Target>
        <Box my={15} mx={30}>
          <IconChevronDown size="18" />
        </Box>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item onClick={logout}>Logout</Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default PostLoginNavbarDropdownMenu;
