import {
  Alert,
  Anchor,
  Autocomplete,
  Badge,
  Button,
  Checkbox,
  List,
  Modal,
  MultiSelect,
  NumberInput,
  Select,
  Tabs,
  TextInput,
  Textarea,
  Tooltip,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { Notifications } from '@mantine/notifications';

import anchorStyles from './mantineAnchor.module.scss';
import checkboxStyles from './mantineCheckbox.module.scss';
import datePickerInputStyles from './mantineDatePickerInput.module.scss';
import listStyles from './mantineList.module.scss';
import modalStyles from './mantineModal.module.scss';
import numberInputStyles from './mantineNumberInput.module.scss';
import selectStyles from './mantineSelect.module.scss';
import tabStyles from './mantineTabs.module.scss';
import textInputStyles from './mantineTextInput.module.scss';
import textareaStyles from './mantineTextarea.module.scss';
import tootlipStyles from './mantineTooltip.module.scss';

export default {
  focusRing: 'never',
  primaryColor: 'lionstep-blue',
  primaryShade: 5,
  colors: {
    'lionstep-blue': [
      '#F6F8FD',
      '#DDE5F9',
      '#BACAF2',
      '#98B0EC',
      '#7695E5',
      '#537BDF',
      '#4262B2',
      '#213057',
      '#192543',
      '#16213C',
    ],
    'lionstep-gray': [
      '#FAFAF9',
      '#F6F5F3',
      '#ECEBE7',
      '#E3E1DB',
      '#D9D7CE',
      '#CFCDC2',
      '#A6A39B',
      '#7C7A74',
      '#686661',
      '#5F5D59',
    ],
  },
  components: {
    Alert: Alert.extend({
      defaultProps: {
        radius: 'md',
      },
    }),
    Anchor: Anchor.extend({
      defaultProps: {
        fz: 'sm',
        underline: 'never',
      },
      classNames: {
        root: anchorStyles.root,
      },
    }),
    Autocomplete: Autocomplete.extend({
      defaultProps: {
        fz: 'sm',
        pb: 'md',
        pos: 'relative',
        radius: 'md',
        size: 'md',
        checkIconPosition: 'right',
        classNames: {
          input: selectStyles.input,
          dropdown: selectStyles.dropdown,
          option: selectStyles.option,
        },
        errorProps: {
          bottom: 0,
          fz: 'xs',
          left: 0,
          pos: 'absolute',
        },
        labelProps: {
          fw: '400',
          fz: 'sm',
        },
      },
      styles: {
        wrapper: {
          marginBottom: 0,
        },
      },
    }),
    Badge: Badge.extend({
      defaultProps: {
        fz: 'xs',
        fw: '500',
        tt: 'unset',
        lh: 'sm',
        px: '15',
        py: '3',
        h: 'auto',
      },
    }),
    Button: Button.extend({
      defaultProps: {
        radius: 'xl',
        size: 'md',
        fz: 'sm',
        fw: 400,
      },
    }),
    Checkbox: Checkbox.extend({
      defaultProps: {
        size: 'md',
      },
      classNames: {
        label: checkboxStyles.label,
      },
    }),
    DatePickerInput: DatePickerInput.extend({
      defaultProps: {
        fz: 'sm',
        pb: 'md',
        pos: 'relative',
        radius: 'md',
        size: 'md',
        valueFormat: 'DD/MM/YYYY',
        errorProps: {
          bottom: 0,
          fz: 'xs',
          left: 0,
          pos: 'absolute',
        },
        labelProps: {
          fw: '400',
          fz: 'sm',
        },
        datePickerProps: {
          size: 'sm',
        },
        popoverProps: {
          fz: 'sm',
          radius: 'md',
          size: 'md',
          classNames: {
            dropdown: datePickerInputStyles.dropdown,
          },
        },
      },
      classNames: {
        input: datePickerInputStyles.input,
        calendarHeaderLevel: datePickerInputStyles.calendarHeaderLevel,
        weekday: datePickerInputStyles.weekday,
        day: datePickerInputStyles.day,
        levelsGroup: datePickerInputStyles.levelsGroup,
      },
      styles: {
        wrapper: {
          marginBottom: 0,
        },
      },
    }),
    List: List.extend({
      classNames: {
        itemLabel: listStyles.listItemLabel,
        itemWrapper: listStyles.listItemWrapper,
      },
    }),
    Modal: Modal.extend({
      defaultProps: {
        radius: 'lg',
        centered: true,
      },
      classNames: {
        content: modalStyles.content,
        header: modalStyles.header,
        title: modalStyles.title,
      },
    }),
    Notifications: Notifications.extend({
      defaultProps: {
        position: 'top-right',
        maw: 400,
      },
    }),
    NumberInput: NumberInput.extend({
      defaultProps: {
        fz: 'sm',
        pb: 'md',
        pos: 'relative',
        radius: 'md',
        size: 'md',
        errorProps: {
          bottom: 0,
          fz: 'xs',
          left: 0,
          pos: 'absolute',
        },
        labelProps: {
          fw: '400',
          fz: 'sm',
        },
      },
      classNames: {
        input: numberInputStyles.input,
      },
      styles: {
        wrapper: {
          marginBottom: 0,
        },
      },
    }),
    Select: Select.extend({
      defaultProps: {
        fz: 'sm',
        pb: 'md',
        pos: 'relative',
        radius: 'md',
        size: 'md',
        checkIconPosition: 'right',
        classNames: {
          input: selectStyles.input,
          dropdown: selectStyles.dropdown,
          option: selectStyles.option,
        },
        errorProps: {
          bottom: 0,
          fz: 'xs',
          left: 0,
          pos: 'absolute',
        },
        labelProps: {
          fw: '400',
          fz: 'sm',
        },
      },
      styles: {
        wrapper: {
          marginBottom: 0,
        },
      },
    }),
    MultiSelect: MultiSelect.extend({
      defaultProps: {
        fz: 'sm',
        pb: 'md',
        pos: 'relative',
        radius: 'md',
        size: 'md',
        checkIconPosition: 'right',
        classNames: {
          input: selectStyles.input,
          dropdown: selectStyles.dropdown,
          option: selectStyles.option,
        },
        errorProps: {
          bottom: 0,
          fz: 'xs',
          left: 0,
          pos: 'absolute',
        },
        labelProps: {
          fw: '400',
          fz: 'sm',
        },
      },
      styles: {
        wrapper: {
          marginBottom: 0,
        },
      },
    }),
    Tabs: Tabs.extend({
      defaultProps: {
        radius: 0,
        classNames: {
          list: tabStyles.list,
          tab: tabStyles.tab,
        },
      },
    }),
    TextInput: TextInput.extend({
      defaultProps: {
        fz: 'sm',
        pb: 'md',
        pos: 'relative',
        radius: 'md',
        size: 'md',
        classNames: {
          input: textInputStyles.input,
        },
        errorProps: {
          bottom: 0,
          fz: 'xs',
          left: 0,
          pos: 'absolute',
        },
        labelProps: {
          fw: '400',
          fz: 'sm',
        },
      },
      styles: {
        wrapper: {
          marginBottom: 0,
        },
      },
    }),
    Textarea: Textarea.extend({
      defaultProps: {
        fz: 'sm',
        pb: 'md',
        pos: 'relative',
        radius: 'md',
        size: 'md',
        classNames: {
          input: textareaStyles.input,
        },
        errorProps: {
          bottom: 0,
          fz: 'xs',
          left: 0,
          pos: 'absolute',
        },
        labelProps: {
          fw: '400',
          fz: 'sm',
        },
      },
      styles: {
        wrapper: {
          marginBottom: 0,
        },
      },
    }),

    Tooltip: Tooltip.extend({
      defaultProps: {
        withArrow: true,
        classNames: {
          tooltip: tootlipStyles.tooltip,
        },
      },
    }),
  },
};
