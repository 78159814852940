import React from 'react';
import { Image, Text, Group } from '@mantine/core';

// Styles
import styles from './postLoginNavbar.module.scss';

const PostLoginNavbarLogo = () => (
  <Group className={styles.logoWrapper} gap="0" my={15} mx={30}>
    <Image
      w="auto"
      h={20}
      src="/img/lionstep-icon-blue.svg"
      alt="Lionstep logo"
      className={styles.logo}
    />
    <Text className={styles.title} size="sm">
      Talentpool
    </Text>
  </Group>
);

export default PostLoginNavbarLogo;
