import React from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error) {
    this.setState({ error });
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;
    if (error) {
      return (
        <div className="max-w-7xl mx-auto px-2 md:px-0">
          <p style={{ fontSize: '16px' }}>
            {"We're sorry — something's gone wrong."}
          </p>
          <p style={{ fontSize: '16px' }}>
            Our team has been notified, but <underline>click here</underline>{' '}
            fill out a report.
          </p>
        </div>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ErrorBoundary;
