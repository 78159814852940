import { v4 } from 'uuid';
import {
  CREATE_NOTIFICATION,
  DELETE_NOTIFICATION,
} from '../types/notificationTypes';

const deleteNotification = (id) => ({
  type: DELETE_NOTIFICATION,
  payload: {
    id,
  },
});

const createNotification =
  (type, title, description) => (dispatch, getState) => {
    const createAction = {
      type: CREATE_NOTIFICATION,
      payload: {
        id: v4(),
        type,
        title,
        description,
      },
    };
    dispatch(createAction);
    setTimeout(() => {
      const { notifications } = getState();
      if (notifications.find((n) => n.id === createAction.payload.id)) {
        dispatch(deleteNotification(createAction.payload.id));
      }
    }, 5000);
  };

const createInfo = (title, description) => (dispatch) => {
  dispatch(createNotification('info', title, description));
};

const createWarning = (title, description) => (dispatch) => {
  dispatch(createNotification('warning', title, description));
};

const createError = (title, description) => (dispatch) => {
  dispatch(createNotification('error', title, description));
};

const createSuccess = (title, description) => (dispatch) => {
  dispatch(createNotification('success', title, description));
};

export {
  createNotification,
  deleteNotification,
  createInfo,
  createWarning,
  createError,
  createSuccess,
};
