import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, isLoaded } from 'react-redux-firebase';

function useUserLogged() {
  const auth = useSelector((state) => state.firebase.auth);
  const [logged, setLogged] = useState(!isEmpty(auth) && isLoaded(auth));

  useEffect(() => {
    if (!isEmpty(auth) && isLoaded(auth)) setLogged(true);
    else setLogged(false);
  }, [auth]);

  return logged;
}

export default useUserLogged;
