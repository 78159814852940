import { capitalize, isEmpty } from 'lodash';

const specialJobValues = [
  'ceo',
  'Ceo',
  'CEO',
  'cto',
  'Cto',
  'CTO',
  'cfo',
  'Cfo',
  'CFO',
];

export const EMPLOYMENT_STATUSES = [
  {
    value: 'is_open',
    label: 'Open',
  },
  {
    value: 'is_not_open',
    label: 'Not open',
  },
  {
    value: 'is_unemployed',
    label: 'Unemployed',
  },
  {
    value: 'don_t_contact_anymore',
    label: "Don't Contact",
  },
  {
    value: 'placed',
    label: 'Placed',
  },
  {
    value: 'no_status',
    label: 'No Status',
  },
];

function stringFormat(str) {
  if (!str) {
    return '';
  }
  if (str === 'n/a') {
    return str;
  }
  if (specialJobValues.includes(str)) {
    return str.toUpperCase();
  }
  return capitalize(str);
}

function extractMainTitle(talent) {
  if (talent.standard_experience && talent.standard_experience.length) {
    const titlesArr = [];

    let titleDisplay = '';
    if (talent.standard_experience[0].title_display) {
      if (
        typeof talent.standard_experience[0].title_display === 'object' &&
        !isEmpty(talent.standard_experience[0].title_display)
      ) {
        titleDisplay = talent.standard_experience[0].title_display.en ?? '';
      }
    } else {
      titleDisplay = talent.standard_experience[0].title_display;
    }

    titlesArr.push(titleDisplay);
    titlesArr.push(talent.standard_experience[0].title_raw);
    titlesArr.push(talent.standard_experience[0].title_mid);

    const filteredTitles = titlesArr.filter((t) => !!t);

    if (filteredTitles.length) {
      return filteredTitles[0];
    }
  }

  return 'n/a';
}

function extractCity(talent) {
  if (talent.standard_location && !isEmpty(talent.standard_location)) {
    return talent.standard_location.city_mid || '';
  }
  return '';
}

function extractCountry(talent) {
  if (talent.standard_location && !isEmpty(talent.standard_location)) {
    return talent.standard_location.country_mid || '';
  }
  return '';
}

export const enhanceTalentData = (talents) => {
  if (!talents || talents.length === 0) {
    return [];
  }

  return talents.map((talent) => ({
    ...talent,
    jobTitle: stringFormat(extractMainTitle(talent)),
    location: stringFormat(`${extractCity(talent)}, ${extractCountry(talent)}`),
  }));
};
