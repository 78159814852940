import * as types from '../types/talentTypes';

import { enhanceTalentData } from '../../utils/talentUtils';

const initialState = {
  talent: null,
  jobInfo: null,
  personality: null,
  error: null,
  loading: false,
  filesLoading: false,
  personalityLoading: false,
};

const talentsReducers = (state = initialState, action) => {
  switch (action.type) {
    // TALENT
    case types.FETCH_SINGLE_TALENT:
      return {
        ...state,
        loading: true,
        talent: null,
      };

    case types.FETCH_SINGLE_TALENT_SUCCESS:
      return {
        ...state,
        talent: enhanceTalentData([action.payload.talent])[0],
        error: null,
        loading: false,
      };

    case types.FETCH_SINGLE_TALENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload?.response?.error_message,
      };

    case types.UPDATE_TALENT:
      return { ...state, loading: true };

    case types.UPDATE_TALENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        talent: enhanceTalentData([
          {
            ...state.talent,
            ...action.payload.es,
            ...action.payload.datastore,
            linkedin_url:
              action.payload.datastore?.url ?? state.talent.linkedin_url,
          },
        ])[0],
      };
    case types.UPDATE_TALENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload?.response?.error_message,
      };

    case types.RESET_TALENT_STATE:
      return initialState;

    // JOB
    case types.FETCH_SINGLE_TALENT_JOB_INFO:
      return {
        ...state,
        loading: true,
        jobInfo: null,
      };

    case types.FETCH_SINGLE_TALENT_JOB_INFO_SUCCESS:
      return {
        ...state,
        jobInfo: action.payload.results,
      };

    case types.FETCH_SINGLE_TALENT_JOB_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload?.response?.error_message,
      };

    // DOCUMENTS
    case types.FETCH_TALENT_DOCUMENT:
      return { ...state };

    case types.FETCH_TALENT_DOCUMENT_SUCCESS:
      return {
        ...state,
        talent: {
          ...state.talent,
          documents_with_original_filename:
            state.talent.documents_with_original_filename.map((item) => {
              if (item.filename === action.payload.document) {
                return {
                  ...item,
                  file: action.payload.file,
                };
              }

              return item;
            }),
        },
      };

    case types.FETCH_TALENT_DOCUMENT_FAILURE:
      return {
        ...state,
        talent: {
          ...state.talent,
          documents_with_original_filename:
            state.talent.documents_with_original_filename.map((item) => {
              if (item.filename === action.payload.document) {
                return {
                  ...item,
                  file: null,
                };
              }

              return item;
            }),
        },
      };

    case types.FETCH_TALENT_SPECIAL_DOCUMENT:
      return { ...state };

    case types.FETCH_TALENT_SPECIAL_DOCUMENT_SUCCESS:
      return {
        ...state,
        talent: {
          ...state.talent,
          documents_special_with_original_filename:
            state.talent.documents_special_with_original_filename.map(
              (item) => {
                if (item.filename === action.payload.document) {
                  return {
                    ...item,
                    file: action.payload.file,
                  };
                }

                return item;
              },
            ),
        },
      };

    case types.FETCH_TALENT_SPECIAL_DOCUMENT_FAILURE:
      return {
        ...state,
        talent: {
          ...state.talent,
          documents_special_with_original_filename:
            state.talent.documents_special_with_original_filename.map(
              (item) => {
                if (item.filename === action.payload.document) {
                  return {
                    ...item,
                    file: null,
                  };
                }

                return item;
              },
            ),
        },
      };

    case types.SET_TALENT_DOCUMENTS_LOADING:
      return {
        ...state,
        filesLoading: true,
      };

    case types.STOP_TALENT_DOCUMENTS_LOADING:
      return {
        ...state,
        filesLoading: false,
      };

    // TODO: !!!
    case types.UPLOAD_TALENT_DOCUMENTS_SUCCESS:
      return {
        ...state,
        talent: {
          ...state.talent,
          documents_with_original_filename: [
            ...state.talent.documents_with_original_filename,
            ...action.payload,
          ],
        },
      };

    case types.DELETE_TALENT_DOCUMENTS_SUCCESS:
      return {
        ...state,
        talent: {
          ...state.talent,
          documents_with_original_filename:
            state.talent.documents_with_original_filename.filter(
              (item) => item.filename !== action.payload,
            ),
        },
      };

    // PERSONALITY
    case types.ENABLE_TALENT_PERSONALITY_TEST:
    case types.GET_TALENT_PERSONALITY_INFO:
      return {
        ...state,
        personalityLoading: true,
      };

    case types.GET_TALENT_PERSONALITY_INFO_SUCCESS:
      return {
        ...state,
        personalityLoading: false,
        personality: action.payload,
      };

    case types.ENABLE_TALENT_PERSONALITY_TEST_FAILURE:
    case types.GET_TALENT_PERSONALITY_INFO_FAILURE:
      return {
        ...state,
        personalityLoading: false,
      };

    default:
      return state;
  }
};

export default talentsReducers;
