import {
  CREATE_NOTIFICATION,
  DELETE_NOTIFICATION,
} from '../types/notificationTypes';

/* State shape
[
  {
    id: uuid,
    type: string,
    title: string,
    description: string,
  }
]
*/

const initialState = [];

const notificationsReducers = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_NOTIFICATION:
      return [...state, action.payload];

    case DELETE_NOTIFICATION:
      return state.filter(
        (notification) => notification.id !== action.payload.id,
      );

    default:
      return state;
  }
};

export default notificationsReducers;
