import React from 'react';
import { useLocation } from 'react-router-dom';
import { Text, UnstyledButton, Box } from '@mantine/core';
import { IconChevronLeft } from '@tabler/icons-react';

// Hooks
import useFilterSearchParams from '../../../../hooks/useFilterSearchParams';

const PostLoginNavbarBackButton = () => {
  const { setFilterSearchParams } = useFilterSearchParams();
  const { pathname, state: locationState } = useLocation();

  return (
    <Box h="50" px={30} display="flex" style={{ alignItems: 'center' }}>
      <UnstyledButton
        style={{ lineHeight: 'normal' }}
        onClick={() => {
          setFilterSearchParams({
            filtersData: locationState?.filterSearchParams?.filtersData,
            otherData: locationState?.filterSearchParams?.otherData,
            pathname: pathname.includes('jobs') ? '/jobs' : '/',
          });
        }}
      >
        <Text
          size="sm"
          display="inline-flex"
          lh={0}
          style={{ alignItems: 'center' }}
        >
          <IconChevronLeft size="18" style={{ marginRight: 4 }} />
          Back to overview
        </Text>
      </UnstyledButton>
    </Box>
  );
};

export default PostLoginNavbarBackButton;
